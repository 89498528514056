<ng-container *transloco="let t">
  <app-widget-container [title]="t('FRONTPAGE.UPCOMING_STUDIES.TITLE')" [subtitle]="t('FRONTPAGE.UPCOMING_STUDIES.SUBTITLE')">
    <ng-container *ngIf="upcomingStudies$ | async as upcomingStudies; else loading">
      <sis-study-realisation-box
        *ngFor="let study of upcomingStudies; trackBy: courseUnitRealisationId; last as last"
        [level]="3"
        [id]="study.courseUnitRealisation.id"
        [realisationName]="study.courseUnitRealisation.name"
        [activityPeriod]="study.courseUnitRealisation.activityPeriod"
        [code]="study.courseUnit?.code"
        [name]="study.courseUnit?.name"
        [credits]="study.assessmentItem?.credits"
        [disableBoxMaxWidth]="true"
        (nameClickAction)="openCourseUnitInfoModal(study.courseUnit?.id, study.courseUnitRealisation)"
        [class.sis-mb-sm]="!last"
        [showCancelledBadge]="isCurCancelled(study)"
      >
        <ng-container study-box="notifications" *ngIf="!isCurCancelled(study)">
          <app-enrolment-state-notification-box [enrolment]="study.enrolment"/>

          <sis-study-notification-box [type]="'info'">
            <app-enrolment-period-info [courseUnitRealisation]="study.courseUnitRealisation"/>
          </sis-study-notification-box>
        </ng-container>

        <ng-container study-box=notifications *ngIf="isCurCancelled(study)">
          <sis-study-notification-box>
            {{ 'ENROLMENTS.CUR_CANCELLED' | translate  }}
          </sis-study-notification-box>
        </ng-container>

      </sis-study-realisation-box>

      <p *ngIf="!upcomingStudies?.length && !searchFailed()" class="app-widget-container__content__info-text sis-mb-sm">
        {{t('FRONTPAGE.UPCOMING_STUDIES.NO_ITEMS')}}
      </p>
    </ng-container>

    <p *ngIf="searchFailed()" class="app-widget-container__content__info-text sis-mb-sm">
      {{t('FRONTPAGE.UPCOMING_STUDIES.FETCHING_STUDIES_FAILED')}}
    </p>
  </app-widget-container>
</ng-container>

<ng-template #loading>
  <div aria-busy="true" class="d-flex justify-content-center">
    <sis-small-spinner />
  </div>
</ng-template>
