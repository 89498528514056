import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { Icon, IconColor } from '../../icon/icon.component';
import { Option } from '../../menuButton/menu-button.component';

export type NotificationType = 'info' | 'alert' | 'waiting' | 'waiting-approval' | 'waiting-decline' | 'approved' | 'declined' | 'substitution' | 'study-path' | 'implicit' | 'none';

@Component({
    selector: 'sis-study-notification-box',
    templateUrl: './study-notification-box.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyNotificationBoxComponent {

    @Input() type?: NotificationType = 'info';
    @Input() primaryButtonText?: string;
    @Input() secondaryButtonText?: string;
    @Input() menuOptions?: Option[];
    @Output() infoClickAction = new EventEmitter<void>();
    @Output() primaryClickAction = new EventEmitter<void>();
    @Output() secondaryClickAction = new EventEmitter<void>();

    getIcon(): Icon {
        switch (this.type) {
            case 'alert':
            case 'waiting-approval':
            case 'waiting-decline':
                return this.type;
            case 'waiting':
                return 'hourglass';
            case 'approved':
                return 'check';
            case 'declined':
                return 'fail';
            case 'substitution':
                return 'switch';
            case 'study-path':
                return 'junction';
            case 'implicit':
                return 'exclamation-mark-circle';
            case 'info':
            default:
                return 'info-circle-fill';
        }
    }

    getIconColor(): IconColor {
        switch (this.type) {
            case 'alert':
            case 'declined':
                return 'danger';
            case 'approved':
                return 'success';
            default:
                return 'default';
        }
    }
}
