import {
    ChangeDetectionStrategy,
    Component, inject,
    Inject,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FudisDialogService, FudisValidators } from '@funidata/ngx-fudis';
import { TranslocoService } from '@ngneat/transloco';
import { MaxLength } from 'common-typescript/constants';
import { CustomStudyDraft, OtmId } from 'common-typescript/types';
import { UuidService } from 'sis-common/uuid/uuid.service';
import { getLabelState } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';

interface DialogInjectData {
    selectedModuleId: OtmId;
}

interface CustomStudyDraftFormValues {
    name: FormControl<string>;
    credits: FormControl<number>;
    location: FormControl<string>;
    description: FormControl<string>;
}

@Component({
    selector: 'app-create-new-custom-study-draft-modal',
    templateUrl: './create-custom-study-draft-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CreateCustomStudyDraftModalComponent implements OnInit {
    private dialogService = inject(FudisDialogService);

    customStudyDraftForm: FormGroup<CustomStudyDraftFormValues>;

    studyDraft: CustomStudyDraft;
    readonly getLabelState = getLabelState;
    selectedModuleId: OtmId;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogInjectData,
        private fb: SisFormBuilder,
        private uuidService: UuidService,
        private translocoService: TranslocoService) {
        if (this.data) {
            this.selectedModuleId = this.data.selectedModuleId;
        }
    }

    ngOnInit(): void {
        this.customStudyDraftForm = this.buildForm();
    }

    buildForm(): FormGroup {
        return this.fb.group({
            name: this.fb.control(
                null,
                ([
                    FudisValidators.maxLength(MaxLength.MAX_SHORT_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH', { maxLength: MaxLength.MAX_SHORT_STRING_LENGTH })),
                    FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                ]),
            ),
            credits: this.fb.control(
                null,
                ([
                    FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                    FudisValidators.min(0, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MIN', { min: 0 })),
                    FudisValidators.max(999, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX', { max: 999 })),
                ]),
            ),
            location: this.fb.control(
                null,
                ([
                    FudisValidators.maxLength(MaxLength.MAX_TERSE_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH', { maxLength: MaxLength.MAX_TERSE_STRING_LENGTH })),
                    FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                ]),
            ),
            description: this.fb.control(
                null,
                ([
                    FudisValidators.maxLength(MaxLength.MAX_LONG_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH', { maxLength: MaxLength.MAX_LONG_STRING_LENGTH })),
                    FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                ]),
            ),
        });
    }

    cancel() {
        this.dialogService.close();
    }

    addStudyDraft() {
        if (!this.customStudyDraftForm.valid) {
            return;
        }
        this.studyDraft = {
            id: this.uuidService.randomUUID(),
            name: this.name.value,
            credits: Number(this.credits.value),
            location: this.location.value,
            description: this.description.value,
            plannedPeriods: [],
            parentModuleId: this.selectedModuleId,
        };
        this.dialogService.close({ operation: 'ADD', customStudyDraft: this.studyDraft });

    }

    get name(): FormControl<string> {
        return this.customStudyDraftForm.controls.name;
    }

    get credits(): FormControl<number> {
        return this.customStudyDraftForm.controls.credits;
    }

    get location(): FormControl<string> {
        return this.customStudyDraftForm.controls.location;
    }

    get description(): FormControl<string> {
        return this.customStudyDraftForm.controls.description;
    }
}

