import { inject, Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnit, CourseUnitRealisation, Enrolment, OtmId } from 'common-typescript/types';
import { compact, find, uniq } from 'lodash';
import moment from 'moment/moment';
import { map, Observable, of } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';

export interface CourseUnitTeaching {
    courseUnitId: OtmId
    /**
     * True if there exists active teaching (course unit realisations) for some assessment item of the given course unit.
     */
    hasSelectableAssessmentItems: boolean
    /**
     * True if there exists active enrolment or attainment for each assessment item of given course unit (or selected
     * completion method if selection exists). Only assessment items with active teaching (course unit realisations) is
     * taken into account.
     */
    allAssessmentItemsSelected: boolean
}

/**
 * Service for getting info of course unit's assessment items with active teaching.
 */
@Injectable()
export class CourseUnitTeachingService {

    private appErrorHandler = inject(AppErrorHandler);
    private courseUnitRealisationEntityService = inject(CourseUnitRealisationEntityService);

    getCourseUnitTeachings(courseUnits: CourseUnit[],
                           validatablePlan: ValidatablePlan,
                           enrolments: Enrolment[]): Observable<CourseUnitTeaching[]> {
        if (!courseUnits?.length) {
            return of([]);
        }

        return this.getActiveRealisationsByCourseUnitAssessmentItems(courseUnits, validatablePlan)
            .pipe(
                map(courseUnitRealisations => this.createCourseUnitTeachings(courseUnits, courseUnitRealisations, validatablePlan, enrolments)),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    private createCourseUnitTeachings(courseUnits: CourseUnit[], courseUnitRealisations: CourseUnitRealisation[], validatablePlan: ValidatablePlan, enrolments: Enrolment[]) {
        const assessmentItemIds = compact(uniq(courseUnitRealisations.flatMap(cur => cur.assessmentItemIds)));
        return courseUnits.map(cu => this.createCourseUnitTeaching(cu, courseUnitRealisations, assessmentItemIds, validatablePlan, enrolments));
    }

    private createCourseUnitTeaching(courseUnit: CourseUnit,
                                     courseUnitRealisations: CourseUnitRealisation[],
                                     assessmentItemIds: OtmId[],
                                     validatablePlan: ValidatablePlan,
                                     enrolments: Enrolment[]): CourseUnitTeaching {
        const assessmentItemIdsFromCourseUnit = this.getAssessmentItemIdsFromCourseUnit(courseUnit, validatablePlan);

        const selectableAssessmentItemIds = assessmentItemIds.filter(id => assessmentItemIdsFromCourseUnit.includes(id));

        const unselectedAssessmentItemIds = selectableAssessmentItemIds
            .filter(assessmentItemId => {
                const isAttained = validatablePlan.isAssessmentItemAttained(assessmentItemId);
                const hasActiveEnrolment = this.assessmentItemHasActiveEnrolment(assessmentItemId, enrolments, courseUnitRealisations);

                return !isAttained && !hasActiveEnrolment;
            });

        return {
            courseUnitId: courseUnit.id,
            hasSelectableAssessmentItems: selectableAssessmentItemIds.length > 0,
            allAssessmentItemsSelected: selectableAssessmentItemIds.length > 0 && unselectedAssessmentItemIds.length === 0,
        };
    }

    private assessmentItemHasActiveEnrolment(assessmentItemId: OtmId, enrolments: Enrolment[], courseUnitRealisations: CourseUnitRealisation[]) {
        const isActiveEnrolment = (enrolment: Enrolment) => ['ENROLLED', 'PROCESSING', 'NOT_ENROLLED'].includes(enrolment.state);

        const isCourseUnitRealisationActive = (enrolment: Enrolment, curs: CourseUnitRealisation[]) => {
            const cur = find(curs, { id: enrolment.courseUnitRealisationId });
            return cur && moment().isSameOrBefore(cur.activityPeriod.endDate);
        };

        return enrolments.some(enrolment => enrolment.assessmentItemId === assessmentItemId && isActiveEnrolment(enrolment) && isCourseUnitRealisationActive(enrolment, courseUnitRealisations));
    }

    private getAssessmentItemIdsFromCourseUnits(courseUnits: CourseUnit[], validatablePlan: ValidatablePlan): OtmId[] {
        const assessmentItemIds = courseUnits.flatMap(cu => this.getAssessmentItemIdsFromCourseUnit(cu, validatablePlan));

        return compact(uniq(assessmentItemIds));
    }

    private getAssessmentItemIdsFromCourseUnit(courseUnit: CourseUnit, validatablePlan: ValidatablePlan): OtmId[] {
        const selectedCompletionMethod = validatablePlan.getSelectedCompletionMethod(courseUnit.id);
        if (selectedCompletionMethod) {
            return selectedCompletionMethod.assessmentItemIds;
        }
        return courseUnit.completionMethods.flatMap(cu => cu.assessmentItemIds);
    }

    private getActiveRealisationsByCourseUnitAssessmentItems(courseUnits: CourseUnit[], validatablePlan: ValidatablePlan): Observable<CourseUnitRealisation[]> {
        const assessmentItemIdsFromCourseUnits = this.getAssessmentItemIdsFromCourseUnits(courseUnits, validatablePlan);

        return this.courseUnitRealisationEntityService.getPublishedAndActiveCourseUnitRealisationsByAssessmentItemIds(assessmentItemIdsFromCourseUnits)
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }
}
