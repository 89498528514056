import { registerLocaleData } from '@angular/common';
import fi from '@angular/common/locales/fi';
import sv from '@angular/common/locales/sv';
import { NgModuleRef, NgZone } from '@angular/core';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { UIRouter, UrlService } from '@uirouter/core';
import * as angular from 'angular';
import { ClipboardService } from 'ngx-clipboard';

import { ComponentDowngradeMappings, DowngradedComponent, DowngradedService, ServiceDowngradeMappings } from '../types/angular-hybrid';

export const ngxClipboardServiceModule = 'ngx-clipboard.clipboard-service';

/**
 * Downgrades Angular components for usage in AngularJS components/directives.
 *
 * @param components an array of Angular component classes that have a `downgrade` member containing their {@link ComponentDowngradeMappings}.
 */
function registerDowngradedComponents(components: DowngradedComponent[]): void {
    components.forEach((component) => {
        const { dependencies, directiveName, moduleName } = component.downgrade;
        angular.module(moduleName, dependencies || [])
            .directive(directiveName, downgradeComponent({ component }));
    });
}

/**
 * Downgrades Angular services for usage in AngularJS.
 *
 * @param services an array of Angular service classes that have a `downgrade` member containing their {@link ServiceDowngradeMappings}.
 */
function registerDowngradedServices(services: DowngradedService[]): void {
    services.forEach((service) => {
        const { dependencies, serviceName, moduleName } = service.downgrade;
        angular.module(moduleName, dependencies || [])
            .factory(serviceName, downgradeInjectable(service));
    });
}

/** Downgrade all services from 3rd party Angular libraries that need to be usable in AngularJS. */
function registerDowngradedServicesFromDependencies(): void {
    // ClipboardService from ngx-clipboard. The ClipboardModule has to also be imported in the app.module
    // of the frontend app where this is used.
    angular.module(ngxClipboardServiceModule, [])
        .factory('clipboardService', downgradeInjectable(ClipboardService));
}

/**
 * Performs Angular related initialization for an Angular/AngularJS hybrid app.
 *
 * @param components an array of Angular component classes that have a `downgrade` member containing their {@link ComponentDowngradeMappings}.
 * @param services an array of Angular service classes that have a `downgrade` member containing their {@link ServiceDowngradeMappings}.
 */
export function initAngular(components: DowngradedComponent[], services: DowngradedService[]): void {
    registerDowngradedComponents(components);
    registerDowngradedServices(services);
    registerDowngradedServicesFromDependencies();
    registerLocaleData(fi);
    registerLocaleData(sv);
}

/**
 * Initializes the hybrid UI-Router.
 *
 * @param platformRef platform reference to get the dependency injector from
 */
export function initUIRouter(platformRef: NgModuleRef<any>) {
    const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;
    platformRef.injector.get<NgZone>(NgZone).run(() => startUrlService(urlService));
    return platformRef;
}

/**
 * Instructs UI-Router to listen to URL changes.
 *
 * @param urlService URL Service instance
 */
export function startUrlService(urlService: UrlService) {
    urlService.listen();
    urlService.sync();
}
