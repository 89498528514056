<ng-container *transloco="let t">
  <div class="sis-plan-structure-module">
    <sis-expandable
      [title]="(module.name | localizedString) + ( targetCredits ? (' (' + (targetCredits | creditRange: 'TEXT') + ')') : '')"
      [contentPadding]="'none'"
      [variant]="'naked-dotted'"
      [closed]="false"
      [level]="headingLevel"
      [elementId]="moduleStateObject?.elementId">
      <ng-template sisExpandableCustomTitle>
        <sis-plan-structure-module-state-badges [documentState]="module.documentState"
                                                [stateObject]="moduleStateObject">
        </sis-plan-structure-module-state-badges>
        <div class="expandable__title__sub-title expandable__title__sub-title__size__md">
          <div class="d-flex flex-column flex-sm-row align-items-center">
            <div class="me-auto me-sm-0">
              <ng-container *ngIf="module.code">{{ module.code }}</ng-container>
              <ng-container
                *ngIf="module.type === 'StudyModule' && module.studyLevel">{{ ' | ' + (module.studyLevel | codeLocalizedName | async) }}
              </ng-container>
            </div>
            <div class="me-auto me-sm-0 ms-sm-auto mt-3 mt-sm-0">
              <sis-plan-structure-module-selection-state
                [stateObject]="moduleStateObject">
              </sis-plan-structure-module-selection-state>
            </div>
          </div>
          <hr class="sis-hr sis-mb-zero sis-mt-xs"/>
        </div>
      </ng-template>
      <ng-template sisExpandableContent>
        <div class="d-flex flex-wrap sis-mb-xs sis-pl-zero">
          <div class="sis-mr-xs sis-pl-zero order-1 open-module-info">
            <sis-button [link]="true" (clicked)="openModuleInfoModal()">
              {{ t('SIS_COMPONENTS.PLAN.OPEN_MODULE_INFO_MODAL') }}
            </sis-button>
          </div>
          <div class="sis-mr-xs w-max-md-100 order-lg-2 order-3 study-credit-info">
            <sis-description-list-compact [fillByColumn]="false">
              <dl>
                <ng-template #compactListItem>
                  <dt class="label colon">{{ t('SIS_COMPONENTS.PLAN.LABEL.SELECTED_CREDITS') }}</dt>
                  <dd
                    class="value">{{ (moduleStateObject?.moduleValidationResult?.plannedCredits | creditRange) ?? '-' }}
                  </dd>
                </ng-template>
                <ng-template #compactListItem>
                  <dt class="label colon">{{ t('SIS_COMPONENTS.PLAN.LABEL.ATTAINED') }}</dt>
                  <dd class="value"
                      *ngIf="moduleStateObject?.moduleValidationResult?.attainedCredits as attainedCredits; else noValue">
                    {{ attainedCredits + ' ' + t('CREDITS') }}
                  </dd>
                  <ng-template #noValue>
                    <dd class="value">{{ '-' }}</dd>
                  </ng-template>
                </ng-template>
              </dl>
            </sis-description-list-compact>
          </div>
          <div class="ms-auto flex-md-row order-2 order-lg-3" *ngIf="!(moduleStateObject?.moduleValidationResult?.state === 'ATTAINED')">
            <sis-button [type]="'button'"
                        [variant]="'primary'"
                        [size]="'sm'"
                        [ariaLabel]="t('SIS_COMPONENTS.PLAN.ARIA.OPEN_PLAN_EDIT_MODAL')"
                        [naked]="true"
                        (clicked)="openModule()">
              <sis-icon [icon]="'three-dots'"></sis-icon>
            </sis-button>
          </div>
        </div>
        <sis-plan-structure-module-content [moduleOrEducationStateObject]="planStateObject?.modules[module.id]"
                                           [moduleOrEducation]="module"
                                           [isImplicit]="isImplicit"
                                           [planValidationResult]="planValidationResult"
                                           [planData]="planData"
                                           [planStateObject]="planStateObject"
                                           [headingLevel]="headingLevel + 1">
        </sis-plan-structure-module-content>
      </ng-template>
    </sis-expandable>
  </div>
</ng-container>


