<ng-container *transloco="let t">
  <sis-study-box [id]="courseUnit.id"
                 [name]="courseUnit.name"
                 [code]="courseUnit.code"
                 [credits]="courseUnit.credits"
                 [level]="headingLevel"
                 [studyState]="studyState"
                 [attainedCredits]="attainment?.credits"
                 [gradeId]="attainment?.gradeId"
                 [gradeScaleId]="attainment?.gradeScaleId"
                 [isSubstituted]="isSubstituted"
                 [isCrossStudyCourseUnit]="isCrossStudyCourseUnit"
                 [action]="action"
                 [selected]="selected"
                 (actionChange)="actionChange.emit($event)"
                 (nameClickAction)="openCourseUnitInfoModal()">
    <ng-container study-box="notifications">
      <sis-study-notification-box *ngIf="invalidSelection" type="alert">{{t('SIS_COMPONENTS.STUDY.INVALID_SELECTION')}}</sis-study-notification-box>
      <sis-study-notification-box *ngIf="courseUnitStateObject?.isInvalidAccordingToModuleContentApprovalRequested" type="alert">{{ t('SIS_COMPONENTS.STUDY.INVALID_ACCORDING_TO_MODULE_CONTENT_APPROVAL') }}</sis-study-notification-box>
      <sis-study-notification-box *ngIf="courseUnitStateObject?.isInvalidAccordingToModuleContentApprovalAccepted" type="alert">{{ t('SIS_COMPONENTS.STUDY.CONTENT_NOT_AS_APPROVED') }}</sis-study-notification-box>
      <sis-study-notification-box *ngIf="isAboutToExpire" type="info">{{ t('SIS_COMPONENTS.STUDY.EXPIRATION_IMMINENT', { expiryDate: courseUnitStateObject.attainmentExpiryDate | localDateFormat }) }}</sis-study-notification-box>
      <sis-study-notification-box *ngIf="newerVersionAvailable" type="info">{{t('SIS_COMPONENTS.PLAN.COURSE_UNIT.NEWER_VERSION_AVAILABLE')}}</sis-study-notification-box>
    </ng-container>
  </sis-study-box>
</ng-container>
