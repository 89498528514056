import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ScrollService {
    private getElementById(id: string) {
        return document.getElementById(id);
    }

    scrollToElementById(id: string) {
        const element = this.getElementById(id);
        if (element) {
            element.scrollIntoView();
            element.focus();
        }
    }
}
