<ng-container *transloco="let t">
  <ng-container *ngIf="ruleCourseUnit()">
    <ng-container *ngIf="isImplicit()">
      <sis-plan-structure-implicit-course-unit [courseUnit]="ruleCourseUnit()"
                                               [headingLevel]="headingLevel"
                                               [planData]="planData"
                                               [selected]="this.ruleValidationResult()?.active"
                                               [action]="this.isRuleSelectionDisabled() ? undefined : 'select-to-plan'"
                                               [planStateObject]="_planStateObject()"
                                               (actionChange)="handleToggle()"
                                               [showSkipLink]="false">
      </sis-plan-structure-implicit-course-unit>
    </ng-container>
    <ng-container *ngIf="!isImplicit()">
      <sis-plan-structure-course-unit [courseUnit]="ruleCourseUnit()"
                                      [headingLevel]="headingLevel"
                                      [planData]="planData"
                                      [selected]="this.ruleValidationResult()?.active"
                                      [action]="this.isRuleSelectionDisabled() ? undefined : 'select-to-plan'"
                                      [planStateObject]="_planStateObject()"
                                      (actionChange)="handleToggle()">
        <sis-study-notification-box *ngIf="ruleValidationResult()?.active && isParentRuleSelectAllRule()">
          {{ t('PLAN_EDIT.SELECTION_MODAL.RULE_BOX_NOTIFICATION.MANDATORY_SELECTION') }}
        </sis-study-notification-box>
      </sis-plan-structure-course-unit>
    </ng-container>

  </ng-container>
</ng-container>
