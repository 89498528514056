<ng-container *transloco="let t">
  <sis-study-box [name]="module.name"
                 [level]="headingLevel"
                 [credits]="module.targetCredits"
                 [code]="module.code"
                 [attainedCredits]="attainment?.credits"
                 [gradeId]="attainment?.gradeId"
                 [gradeScaleId]="attainment?.gradeScaleId"
                 [type]="boxType"
                 [id]="module.id"
                 [studyState]="studyState"
                 [selected]="selected"
                 [action]="action"
                 (actionChange)="actionChange.emit($event)"
                 (nameClickAction)="openDegreeProgrammeInfoModal()">
    <ng-container study-box="notifications">
      <sis-study-notification-box *ngIf="invalidSelection" type="alert">{{ t('SIS_COMPONENTS.STUDY.INVALID_SELECTION') }}</sis-study-notification-box>
    </ng-container>
  </sis-study-box>
</ng-container>
