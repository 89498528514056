import {
    ChangeDetectionStrategy,
    Component, DestroyRef,
    Inject,
    inject,
    Input,
    OnInit,
    Optional,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { TranslocoService } from '@ngneat/transloco';
import { ValidatablePlan } from 'common-typescript';
import { CustomStudyDraft } from 'common-typescript/types';
import _ from 'lodash';
import {
    exhaustMap,
    merge,
    mergeMap,
    Observable,
    of,
    Subject, take,
    withLatestFrom,
} from 'rxjs';
import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from 'sis-components/plan/plan-actions-service/plan-actions.service';
import { PlanManager, PlanOperationType } from 'sis-components/plan/plan-manager/plan-manager.service';
import { ReadMoreModalService } from 'sis-components/read-more-ng/read-more-modal.service';
import { ReadMoreValues } from 'sis-components/read-more-ng/read-more-ng-modal/read-more-ng-modal.component';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { StudyBoxType } from 'sis-components/study-boxes/study-box/study-box.component';

import {
    CreateCustomStudyDraftModalComponent,
} from '../../../../plan/create-custom-study-draft-modal/create-custom-study-draft-modal.component';
import { PlanStructureEditModalValues } from '../plan-structure-edit-modal.component';

@Component({
    selector: 'app-plan-structure-free-edit',
    templateUrl: './plan-structure-free-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class PlanStructureFreeEditComponent implements OnInit {
    private dialogService = inject(FudisDialogService);
    @Input() modalValues: PlanStructureEditModalValues;

    protected readonly StudyBoxType = StudyBoxType;
    submitClick$: Subject<void> = new Subject();
    checkedCustomStudyDrafts: CustomStudyDraft[] = [];
    allCustomStudyDrafts: CustomStudyDraft[] = [];

    data$: Observable<ValidatablePlan>;

    constructor(
        private readMoreModalService: ReadMoreModalService,
        private translocoService: TranslocoService,
        private planEntityService: PlanEntityService,
        private destroyRef: DestroyRef,
        private planManager: PlanManager,
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService) {}

    ngOnInit(): void {
        this.planManager.setValidatablePlan(_.cloneDeep(this.modalValues.validatablePlan));
        this.checkedCustomStudyDrafts = this.getCustomStudyDrafts(this.modalValues.validatablePlan);
        this.allCustomStudyDrafts = this.getCustomStudyDrafts(this.modalValues.validatablePlan);
        this.data$ = this.createDataObservable();
        this.createPlanOperationSubjectSubscription();
        this.createSubmitClickSubscription();
    }

    /**
     * Subscribes to planOperationSubject and processes the operations by passing them directly to planManager.
     */
    createPlanOperationSubjectSubscription(): void {
        this.planActionsService.planOperationSubject.pipe(
            takeUntilDestroyed(this.destroyRef),
            mergeMap((operation) => this.planManager.processPlanOperation(operation)),
        ).subscribe();
    }

    createDataObservable() {
        return merge(of(this.modalValues.validatablePlan), this.planManager.validatablePlanSubject);
    }

    createSubmitClickSubscription() {
        this.submitClick$.pipe(
            withLatestFrom(this.data$),
            exhaustMap(([voidValue, data]) => this.planEntityService.updateMyPlan(data.plan)
                .pipe(
                    take(1),
                ),
            ))
            .subscribe(() => {
                this.dialogService.closeAll();
            });
    }

    getCustomStudyDrafts(validatablePlan: ValidatablePlan): CustomStudyDraft[] {
        return validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(this.modalValues.module.id);
    }

    dismiss() {
        this.dialogService.close();
    }

    openAddStudyDraftModal() {
        this.dialogService.open(CreateCustomStudyDraftModalComponent, { data: { selectedModuleId: this.modalValues.module.id } })
            .afterClosed()
            .subscribe((result) => {
                if (_.get(result, 'operation') === 'ADD' && _.get(result, 'customStudyDraft')) {
                    this.planManager.processPlanOperation(
                        {
                            parentModule: this.modalValues.module,
                            planOperationType: PlanOperationType.ADD_CUSTOM_STUDY_DRAFT,
                            target: result.customStudyDraft,
                            parentCourseUnit: null,
                        },
                    ).then(() => {
                        this.allCustomStudyDrafts.push(result.customStudyDraft);
                        this.checkedCustomStudyDrafts.push(result.customStudyDraft);
                    });
                }
            });
    }

    openReadMoreAboutStudyDraftModal() {
        this.readMoreModalService.open(this.getReadMoreOptions());
    }

    getReadMoreOptions(): ReadMoreValues {
        return {
            options: {
                contentHtml: null,
                contentTranslateKey: 'PLAN.READ_MORE',
                contentTranslateParameters: null,
                title: this.translocoService.translate('PLAN.CUSTOM_STUDY_DRAFTS_TITLE'),
            },
        };
    }

    customStudyDraftIsChecked(customStudyDraft: CustomStudyDraft): boolean {
        return _.some(this.checkedCustomStudyDrafts, { id: customStudyDraft.id });
    }

    handleToggle(customStudyDraft: CustomStudyDraft): void {
        if (this.customStudyDraftIsChecked(customStudyDraft)) {
            this.planManager.processPlanOperation(
                {
                    parentModule: this.modalValues.module,
                    planOperationType: PlanOperationType.UNSELECT_CUSTOM_STUDY_DRAFT_BY_ID,
                    target: customStudyDraft.id,
                    parentCourseUnit: null,
                },
            ).then(() => _.remove(this.checkedCustomStudyDrafts, (csd) => csd.id === customStudyDraft.id));
        } else {
            this.planManager.processPlanOperation(
                {
                    parentModule: this.modalValues.module,
                    planOperationType: PlanOperationType.ADD_CUSTOM_STUDY_DRAFT,
                    target: customStudyDraft,
                    parentCourseUnit: null,
                },
            ).then(() => this.checkedCustomStudyDrafts.push(customStudyDraft));
        }
    }
}
