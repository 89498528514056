import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { CourseUnit, CurriculumPeriod, Plan } from 'common-typescript/types';
import _ from 'lodash';
import { Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { AUTH_SERVICE, DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';
import { CURRICULUM_PERIOD_SERVICE } from 'sis-components/ajs-upgraded-modules';
import {
    CourseUnitDisplayNamesById, CourseUnitInfoService,
    CourseUnitInfoVersion,
    PreviewModeConfig,
} from 'sis-components/courseUnitInfo/course-unit-info.service';
import { Option } from 'sis-components/menuButton/menu-button.component';
import { SelectOption } from 'sis-components/select/select-combobox/select-combobox.component';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { ModalTab } from './course-unit-info-modal-header-tabs/course-unit-info-modal-header-tabs.component';

@Component({
    selector: 'app-course-unit-info-modal-header',
    templateUrl: './course-unit-info-modal-header.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitInfoModalHeaderComponent implements OnChanges, OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.downgraded.courseUnitModalInfoHeader.sisCourseUnitInfoModalHeader',
        directiveName: 'appCourseUnitInfoModalHeader',
    };

    @Input() courseUnit: CourseUnit;
    @Input() originalCourseUnit: CourseUnit;
    @Input() openTab: 'BASIC' | 'COMPLETION_METHODS' | 'SUBSTITUTIONS' | 'OPEN_UNIVERSITY_OFFERING';
    @Input() tabs: ModalTab[];
    @Input() previewModeConfig: PreviewModeConfig;
    @Input() menuOptions: Option[];
    @Input() plan: Plan;
    @Input({ required: true }) validatablePlan: ValidatablePlan;
    @Input() versionSwitchAllowed: boolean;
    @Input() showVersionSwitchNotification: boolean;

    @Output() closeModal = new EventEmitter<any>();
    @Output() setModalCourseUnitVersion = new EventEmitter<CourseUnit>();
    @Output() saveCourseUnitVersionChange = new EventEmitter<void>();
    @Output() selectTab = new EventEmitter<string>();

    versionChanged = false;
    hasNewerVersion = false;
    currentCurriculumPeriod: CurriculumPeriod;
    isLoggedIn = false;
    options: SelectOption[];
    courseUnitVersions: CourseUnitInfoVersion[];
    validatablePlanSubject: ReplaySubject<ValidatablePlan> = new ReplaySubject<ValidatablePlan>(1);
    selectableVersionsAndNames$: Observable<[CourseUnitInfoVersion[], CourseUnitDisplayNamesById]>;
    originalCourseUnitHasSubstitutions = false;

    constructor(@Inject(AUTH_SERVICE) private authService: any,
                private courseUnitInfoService: CourseUnitInfoService,
                private changeDetectorRef: ChangeDetectorRef,
                @Inject(CURRICULUM_PERIOD_SERVICE) private commonCurriculumPeriodService: any,
                @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
    ) {}

    ngOnInit() {
        this.isLoggedIn = this.authService.loggedIn();
        this.originalCourseUnitHasSubstitutions = this.validatablePlan.isSubstituted(this.originalCourseUnit);
        this.getCurrentCurriculumPeriod().then(() => {
            this.initSelectableVersionsAndNamesObservables();
            this.changeDetectorRef.markForCheck();
        });
    }

    getCurrentCurriculumPeriod(): Promise<CurriculumPeriod | void> {
        return convertAJSPromiseToNative(this.commonCurriculumPeriodService.getCurrentCurriculumPeriod())
            .then((curriculumPeriod: CurriculumPeriod) => {
                this.currentCurriculumPeriod = curriculumPeriod;
            })
            .catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.courseUnit || changes.originalCourseUnit) {
            this.versionChanged = this.originalCourseUnit?.id !== this.courseUnit.id;
        }
        if (changes.validatablePlan) {
            this.validatablePlanSubject.next(this.validatablePlan);
        }
    }

    initSelectableVersionsAndNamesObservables() {
        // get new versions and names on init (plan or no plan) AND whenever the validatable plan changes
        this.selectableVersionsAndNames$ = this.validatablePlanSubject.pipe(
            switchMap((validatablePlan: ValidatablePlan) => this.getSelectableVersionsAndNames(validatablePlan)),
        );
    }

    showVersionChangeSuggestionNotification(): boolean {
        if (this.isCourseUnitAttained()) {
            return false;
        }
        const currentCuVersion = this.courseUnitVersions.find(cuv => _.eq(cuv.courseUnit.id, this.courseUnit.id));
        const doesCurrentCurriculumPeriodExistInCurrentCuVersion = this.currentCurriculumPeriodExistInCuVersion(currentCuVersion);
        const someCourseUnitVersionHasCurrentCurriculumPeriod = this.courseUnitVersions
            .some(cuVersion => this.currentCurriculumPeriodExistInCuVersion(cuVersion));

        if (doesCurrentCurriculumPeriodExistInCurrentCuVersion) { // Check if current curriculum period exists in selected course unit version
            return false;
        } return someCourseUnitVersionHasCurrentCurriculumPeriod;

    }

    isCourseUnitAttained(): boolean {
        return this.validatablePlan?.isCourseUnitAttained(this.courseUnit.id);
    }

    currentCurriculumPeriodExistInCuVersion(currentVersion: CourseUnitInfoVersion) {
        return currentVersion?.curriculumPeriods.some(cp => _.eq(cp.activePeriod, this.currentCurriculumPeriod?.activePeriod));
    }

    getSelectableVersionsAndNames(validatablePlan: ValidatablePlan): Observable<[CourseUnitInfoVersion[], CourseUnitDisplayNamesById]> {
        return this.courseUnitInfoService.getSelectableVersionsAndNamesByCu(this.courseUnit, this.previewModeConfig).pipe(
            take(1),
            tap(([courseUnitVersions, displayNamesByCourseUnitId]: [CourseUnitInfoVersion[], CourseUnitDisplayNamesById]) => {
                this.courseUnitVersions = courseUnitVersions;
                this.hasNewerVersion = this.showVersionChangeSuggestionNotification();
                this.options = this.courseUnitInfoService.createSelectOptionsForModal(courseUnitVersions, displayNamesByCourseUnitId, validatablePlan);
            }),
        );
    }

    saveVersionChange() {
        this.saveCourseUnitVersionChange.emit();
        const headerContainer = document.getElementById('student-course-unit-modal-heading');
        headerContainer.focus();
    }
}
