<ng-container *transloco="let t">
        <h2 class="fudis-heading__xl">
            {{ 'ENROLMENTS.SELECT_REALISATIONS' | transloco }}
        </h2>
        <div class="modal-header sis-mb-md">
            <div>
                <p>
                    {{ 'ENROLMENTS.SELECT_REALISATIONS_HELP' | transloco }}
                </p>
            </div>
            <sis-modal-close-button/>
        </div>
        <div class="modal-body">
            <div class="sis-mb-md">
              <h3>{{t('ENROLMENT.SELECT_COURSE_UNIT_REALISATION_DIALOG.COURSE_UNIT_INFO_HEADING')}}</h3>
                <div>
        <span class="control-label form-control-static tiny-static-form-group ng-scope">
          {{t('ENROLMENTS.ENROLMENT_DIALOG.COURSE_UNIT')}}:
          <fudis-link externalLink="/student/courseunit/{{courseUnit.id}}" title="{{courseUnit.code}}, {{courseUnit.name | localizedString}}" class="description-text"></fudis-link>
        </span>
                </div>
              <div>
                  <span class="control-label form-control-static tiny-static-form-group ng-scope">
            {{t('ENROLMENT.SELECT_COURSE_UNIT_REALISATION_DIALOG.COURSE_UNIT_VERSION_INFO')}}: <span class="description-text">{{ courseUnitVersionDisplayName | async }}</span>
                </span>
                      <sis-popover
                        [popoverText]="t('ENROLMENT.SELECT_COURSE_UNIT_REALISATION_DIALOG.COURSE_UNIT_VERSION_TOOLTIP')"
                        popoverClass="dark"
                        popoverPlacement="bottom">
                        <button type="button" class="non-button sis-popover-trigger-content" [attr.aria-label]="t('ARIA_LABEL.SHOW_DETAILS')">
                          <sis-icon [icon]="'info-circle'" [color]="'primary'"></sis-icon>
                        </button>
                      </sis-popover>
              </div>
                <div>
          <span class="control-label form-control-static tiny-static-form-group ng-scope">
            {{t('ENROLMENTS.PLANNED_COMPLETION_METHOD')}}: <span class="description-text">{{t('COMPLETION_METHOD')}}
              : {{ getCompletionMethodIndex() }}</span>
          </span>
                  <sis-popover
                               [popoverText]="t('ENROLMENTS.SELECT_COMPLETION_METHOD_INFO')"
                               popoverClass="dark"
                               popoverPlacement="bottom">
                    <button type="button" class="non-button sis-popover-trigger-content" [attr.aria-label]="t('ARIA_LABEL.SHOW_DETAILS')">
                      <sis-icon [icon]="'info-circle'" [color]="'primary'"></sis-icon>
                    </button>
                  </sis-popover>
                </div>
            </div>
            <ng-container *ngFor="let entry of assessmentItemsWithRealisations | keyvalue">
                <div class="sis-mb-md" *ngIf="entry.value.length > 0">
                    <div class="flex-container">
                      <h3>{{ entry.key.name | localizedString }} ({{ entry.key.credits | creditRange }})</h3>
                      <ng-container *ngIf="hasAnyAttainment(entry.value, entry.key)">
                        <sis-icon icon="achievement" color="success"></sis-icon>
                        <span>{{t('COMPLETED')}}</span>
                      </ng-container>
                    </div>
                  <ng-container *ngFor="let realisation of entry.value">
                      <div class="realisation-container sis-mb-xs sis-pl-sm" [ngClass]="isCourseUnitRealisationSelected(realisation, entry.key) || hasAttainment(realisation, entry.key) ? 'selected' : 'unselected'">
                            <div class="row flex-container">
                              <div class="col-md-6 col-xs-12">
                                <sis-checkbox [label]="getCourseUnitRealisationLabel(realisation)"
                                              [checked]="isCourseUnitRealisationSelected(realisation, entry.key) || hasAttainment(realisation, entry.key)"
                                              (checkedChange)="courseUnitRealisationCheckboxClicked($event, realisation.id, entry.key.id)"
                                              [ariaDescribedBy]=""
                                              [disabled]="isSelectionDisabled(realisation, entry.key)">
                                </sis-checkbox>
                              </div>
                              <div class="col-md-6 col-xs-12">
                                <div class="badge-container flex-container">
                                  <sis-tiny-badge *ngIf="realisation.flowState === 'CANCELLED'" [content]="t('COURSE_UNIT_REALISATION.FLOWSTATE.CANCELLED')" [variant]="'danger'"></sis-tiny-badge>
                                  <sis-tiny-badge *ngIf="hasEnrolled(realisation)" [content]="t('ENROLMENT.SELECT_COURSE_UNIT_REALISATION_DIALOG.ENROLMENT_ATTEMPTED')" [variant]="'primary'"></sis-tiny-badge>
                                  <sis-tiny-badge *ngIf="enrolmentConfirmed(realisation) && !hasAttainment(realisation, entry.key)" [content]="t('ENROLMENT.SELECT_COURSE_UNIT_REALISATION_DIALOG.ENROLMENT_SUCCEEDED')" [variant]="'success'"></sis-tiny-badge>
                                  <sis-tiny-badge *ngIf="enrolmentRejected(realisation)" [content]="t('ENROLMENT.SELECT_COURSE_UNIT_REALISATION_DIALOG.ENROLMENT_REJECTED')" [variant]="'danger'"></sis-tiny-badge>
                                  <sis-tiny-badge *ngIf="hasAborted(realisation)" [content]="t('ENROLMENT.SELECT_COURSE_UNIT_REALISATION_DIALOG.ENROLMENT_ABORTED')" [variant]="'secondary'"></sis-tiny-badge>
                                  <sis-tiny-badge *ngIf="hasAttainment(realisation, entry.key)" [content]="t('ATTAINMENT_STATE.ATTAINED')" [variant]="'success'"></sis-tiny-badge>
                                  <div class="flex-container" *ngIf="isEnrolledToFromAnotherEntity(realisation, entry.key)">
                                    <sis-tiny-badge [content]="t('ENROLMENT.SELECT_COURSE_UNIT_REALISATION_DIALOG.IS_ENROLLED_TO_FROM_ANOTHER_ENTITY_TITLE')" [variant]="'accent'"></sis-tiny-badge>
                                    <sis-popover
                                      [popoverText]="t('ENROLMENT.SELECT_COURSE_UNIT_REALISATION_DIALOG.IS_ENROLLED_TO_FROM_ANOTHER_ENTITY_TOOLTIP')"
                                      popoverClass="dark"
                                      popoverPlacement="bottom">
                                      <button type="button" class="non-button sis-popover-trigger-content" [attr.aria-label]="t('ARIA_LABEL.SHOW_DETAILS')">
                                        <sis-icon [icon]="'info-circle'" [color]="'primary'"></sis-icon>
                                      </button>
                                    </sis-popover>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="sis-pl-md">
                              <ul>
                                <li class="description-text">
                                  <fudis-link externalLink="/student/courseunit/{{courseUnit.id}}/completion-methods?courseUnitRealisation={{realisation.id}}&tabId=0" [title]="t('ENROLMENT.SELECT_COURSE_UNIT_REALISATION_DIALOG.REALISATION_TIME_AND_PLACE_LINK_TEXT')"></fudis-link>
                                </li>
                              </ul>
                            </div>
                      </div>
                  </ng-container>
                </div>
            </ng-container>
            <div>
              <h3>{{t('ENROLMENT.SELECT_COURSE_UNIT_REALISATION_DIALOG.AFTER_CONFIRM_HEADING')}}</h3>
                <p>
                    {{ 'ENROLMENTS.REMEMBER_TO_ENROL' | transloco }}
                </p>
            </div>
        </div>
        <div class="modal-footer confirm-dialog-footer">
            <sis-button (clicked)="cancel()" [hollow]="true">{{ t('BUTTON.CANCEL') }}</sis-button>
            <sis-button dataCy="course-unit-realisation-selection-button"  (clicked)="submit()">{{ t('BUTTON.CONFIRM') }}</sis-button>
        </div>
</ng-container>
