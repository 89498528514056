<ng-container *ngIf="data$ | async as validatablePlan">
  <ng-container *transloco="let t">
      <fudis-dialog class="free-edit-dialog">
        <fudis-heading sisAutofocus [level]="2">{{ t('SIS_COMPONENTS.PLAN.FREE_EDIT.TITLE') + ': ' + (this.modalValues.module.name | localizedString) }}</fudis-heading>
        <fudis-dialog-content>
          <fudis-expandable [title]="t('SIS_COMPONENTS.PLAN.FREE_EDIT.PLAN_STRUCTURE_FREE_EDIT.CUSTOM_STUDY_DRAFTS')" [closed]="false">
            <ng-template fudisContent [type]="'expandable'">
              <fudis-body-text>{{ t('SIS_COMPONENTS.PLAN.FREE_EDIT.PLAN_STRUCTURE_FREE_EDIT.CUSTOM_STUDY_DRAFTS_INFORMATION') }}</fudis-body-text>
              <sis-button
                [label]="t('SIS_COMPONENTS.PLAN.FREE_EDIT.PLAN_STRUCTURE_FREE_EDIT.CUSTOM_STUDY_DRAFTS_READ_MORE')"
                (clicked)="openReadMoreAboutStudyDraftModal()"
                link="true">
              </sis-button>
              <div class="sis-pt-md sis-pb-xs">
                <fudis-button
                  [label]="t('SIS_COMPONENTS.PLAN.FREE_EDIT.PLAN_STRUCTURE_FREE_EDIT.ADD_NEW_CUSTOM_STUDY_DRAFT')"
                  [variant]="'secondary'"
                  [size]="'small'"
                  [disabled]="false"
                  (handleClick)="openAddStudyDraftModal()"
                ></fudis-button>
              </div>
              <div class="sis-pt-sm" *ngFor="let customStudyDraft of allCustomStudyDrafts">
                <sis-study-box
                  [type]="StudyBoxType.studyDraft"
                  [selected]="true"
                  [action]="'select-to-plan'"
                  (actionChange)="handleToggle(customStudyDraft)"
                  [id]="customStudyDraft.id"
                  [name]="{ fi: customStudyDraft.name, sv: customStudyDraft.name, en: customStudyDraft.name }"
                  [credits]="{ max: customStudyDraft.credits, min: customStudyDraft.credits }">
                </sis-study-box>
              </div>
            </ng-template>
          </fudis-expandable>
        </fudis-dialog-content>
        <fudis-dialog-actions>
          <fudis-button fudisDialogClose [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')" (handleClick)="dismiss()" [variant]="'secondary'" />
          <fudis-button [label]="t('SIS_COMPONENTS.BUTTON.SAVE')" (handleClick)="submitClick$.next()" />
        </fudis-dialog-actions>
      </fudis-dialog>
  </ng-container>
</ng-container>

