import { Component, inject, Inject, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import { Education, PlanEducationOptions, StudyRight } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

export interface PlanStudyRightValues {
    education: Education;
    educationOptions: PlanEducationOptions;
    studyRight: StudyRight;
    validatablePlan: ValidatablePlan;
}

export function planStudyRightModalOpener(): (modalValues: PlanStudyRightValues) => NgbModalRef {
    const modalService = inject(ModalService);
    return modalValues =>
        modalService.open(PlanStudyRightModalComponent, modalValues, { size: 'xl' });
}

@Component({
    selector: 'app-plan-study-right-modal',
    template: '<plan-study-right-modal (close)="activeModal.close($event.$value)" (dismiss)="activeModal.dismiss($event ? $event.$value : null)" [resolve]="values"></plan-study-right-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class PlanStudyRightModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: PlanStudyRightValues,
                public activeModal: NgbActiveModal) {}
}
