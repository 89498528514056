import { ChangeDetectionStrategy, Component, inject, signal, ViewEncapsulation } from '@angular/core';
import { CourseUnitRealisation, OtmId } from 'common-typescript/types';
import { catchError, Observable, of } from 'rxjs';
import { trackByNestedEntityId } from 'sis-components/util/utils';

import { COURSE_UNIT_INFO_MODAL } from '../../../ajs-upgraded-modules';

import { ActiveStudiesService, StudyData } from './active-studies.service';

@Component({
    selector: 'app-active-studies',
    templateUrl: './active-studies.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveStudiesComponent {

    readonly activeStudies$: Observable<StudyData[]> = inject(ActiveStudiesService).getActiveStudies()
        .pipe(
            catchError(() => {
                this.searchFailed.set(true);
                return of([]);
            }),
        );

    readonly searchFailed = signal(false);

    private readonly courseUnitInfoModal = inject(COURSE_UNIT_INFO_MODAL);

    readonly courseUnitRealisationId = trackByNestedEntityId<StudyData>('courseUnitRealisation');

    openCourseUnitInfoModal(courseUnitId: OtmId, courseUnitRealisation: CourseUnitRealisation) {
        this.courseUnitInfoModal.showCourseUnitRealisationForCourseUnit(courseUnitId, courseUnitRealisation);
    }

    isCurCancelled(data: StudyData): boolean {
        return data.courseUnitRealisation?.flowState === 'CANCELLED';
    }
}
