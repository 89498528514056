<ng-container *transloco="let t">
  <ng-container *ngIf="assessmentItems$ | async">
  <form>
    <fudis-heading [level]="2">{{ t('SELECT_COMPLETION_METHOD_MODAL.TITLE') }}</fudis-heading>
    <p class="sis-mb-md">{{ t('SELECT_COMPLETION_METHOD_MODAL.MODAL_HELP_TEXT') }}</p>
    <div class="modal-header">
      <sis-modal-close-button/>
    </div>
    <div class="modal-body">
      <div class="sis-mb-md">
        <fudis-heading [level]="3">{{ t('SELECT_COMPLETION_METHOD_MODAL.CU_INFO_TITLE') }}</fudis-heading>
        <div>
          <span class="control-label form-control-static tiny-static-form-group">
            {{ t('SELECT_COMPLETION_METHOD_MODAL.CU_INFO_CU') }}
            <span class="description-text">
              <fudis-link [externalLink]="'/student/courseunit/' + _values.courseUnit.id"
                          [title]="_values.courseUnit.name | localizedString"></fudis-link>
            </span>
          </span>
        </div>
        <div>
          <span class="control-label form-control-static tiny-static-form-group">
            {{ t('SELECT_COMPLETION_METHOD_MODAL.CU_INFO_VERSION') }}
            <span class="description-text">
              {{ _values.courseUnit.curriculumPeriodIds | curriculumPeriodName | async }}
              <sis-popover
                [popoverText]="t('SELECT_COMPLETION_METHOD_MODAL.CU_INFO_VERSION_GUIDE')"
                popoverClass="dark"
                popoverPlacement="bottom">
                <button type="button" class="non-button sis-popover-trigger-content" [attr.aria-label]="t('SELECT_COMPLETION_METHOD_MODAL.CU_INFO_VERSION_GUIDE_BUTTON')">
                  <sis-icon [icon]="'info-circle'" [color]="'primary'"></sis-icon>
                </button>
              </sis-popover>
            </span>
          </span>
        </div>
      </div>

      <fieldset>
        <legend id="select-completion-method"
                tabindex="-1"
                class="fudis-heading__lg sis-mb-md"
                aria-describedby="select-completion-method__errors">{{ t('SELECT_COMPLETION_METHOD_MODAL.CU_CM_TITLE') }}</legend>
        <ng-container *ngFor="let completionMethod of getCompletionMethods(); let index = index">
          <div class="completion-method-box" [class]="selectCompletionMethod()?.value === completionMethod.localId ? 'selected' : 'not-selected'">
            <ng-container *ngIf="{label: getCompletionMethodLabel(completionMethod, index)} as data">
              <sis-radio-button
                [id]="completionMethod.localId" [ariaDescribedBy]="completionMethod.localId + '_errors'"
                [control]="selectCompletionMethod()"
                [value]="completionMethod.localId"
                [label]="data.label" class="d-block fudis-heading__md"></sis-radio-button>
              <ng-container
                *ngTemplateOutlet="completionMethodContent; context: { completionMethod: completionMethod, index: index, label: data.label }"></ng-container>
            </ng-container>
          </div>
        </ng-container>
        <sis-validation-errors formFieldId="select-completion-method" [control]="selectCompletionMethod()" ></sis-validation-errors>
      </fieldset>
      <ng-template #completionMethodContent let-completionMethod="completionMethod" let-index="index">
        <div class="sis-mb-sm">
          <div class="sis-pl-xl">
            <fudis-dl [variant]="'compact'">
              <fudis-dl-item *ngIf="completionMethod.typeOfRequire === 'OPTIONAL_WITH_REQUIRE_RANGE'"
                             id="range-help-{{index}}">
                <fudis-dt [textContent]="t('COMPLETION_METHODS_LIST.DETAIL_VIEW.REQUIRED_RANGE')"></fudis-dt>
                <fudis-dd [textContent]="completionMethod.require | intRange"></fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item *ngIf="completionMethod.typeOfRequire !== 'ALL_SELECTED_REQUIRED'"
                             id="description-help-{{index}}">
                <fudis-dt [textContent]="t('COMPLETION_METHODS_LIST.DETAIL_VIEW.INSTRUCTIONS')"></fudis-dt>
                <fudis-dd *ngIf="completionMethod.assessmentItemOptionalityDescription as description;"
                          [textContent]="description ? (description | localizedMarkupString) : t('COMPLETION_METHODS_LIST.DETAIL_VIEW.NO_INSTRUCTIONS')"></fudis-dd>
              </fudis-dl-item>
            </fudis-dl>
            <ul *ngIf="completionMethod.typeOfRequire === 'ALL_SELECTED_REQUIRED'; else requireSelection">
              <li *ngFor="let assessmentItemId of completionMethod.assessmentItemIds">
                <div *ngIf="assessmentItemId | assessmentItem | async as assessmentItem">
                  <span class="assessment-item-label">{{ getAssessmentItemLabel(assessmentItem) }}</span>
                  <fudis-badge *ngIf="_values.validatablePlan.isAssessmentItemAttained(assessmentItemId)" variant="success">{{t('SELECT_COMPLETION_METHOD_MODAL.ASSESSMENT_ITEM_ATTAINED')}}</fudis-badge>
                </div>
              </li>
            </ul>
            <ng-template #requireSelection>
              <ng-container *ngFor="let assessmentItemId of completionMethod.assessmentItemIds">
                <div class="assessment-item-checkbox sis-mt-sm" *ngIf="assessmentItemId | assessmentItem | async as assessmentItem">
                  <sis-checkbox class="assessment-item-label" [ngClass]="{'invalid': selectedAssessmentItems().invalid && selectedAssessmentItems().touched &&
                  !isAssessmentItemSelectedInCompletionMethod(assessmentItemId, completionMethod) && selectCompletionMethod()?.value === completionMethod.localId}"
                                [label]="getAssessmentItemLabel(assessmentItem)"
                                [checked]="isAssessmentItemSelectedInCompletionMethod(assessmentItemId, completionMethod)"
                                (checkedChange)="assessmentItemCheckboxClicked($event, completionMethod, assessmentItemId)"
                                [ariaDescribedBy]="getAriaDescribedByIndex(index)">
                  </sis-checkbox>
                  <fudis-badge *ngIf="_values.validatablePlan.isAssessmentItemAttained(assessmentItemId)" class="sis-ml-xs" variant="success">{{t('SELECT_COMPLETION_METHOD_MODAL.ASSESSMENT_ITEM_ATTAINED')}}</fudis-badge>
                </div>
              </ng-container>
              <sis-validation-errors *ngIf="selectCompletionMethod()?.value === completionMethod.localId" [formFieldId]="completionMethod.localId" [control]="selectedAssessmentItems()"></sis-validation-errors>
            </ng-template>
          </div>
        </div>
      </ng-template>

      <div class="sis-mt-md">
        <fudis-heading [level]="3">{{ t('SELECT_COMPLETION_METHOD_MODAL.INFO_TITLE') }}</fudis-heading>
        <p>{{ t('SELECT_COMPLETION_METHOD_MODAL.INFO_TEXT') }}</p>
      </div>
    </div>
    <div class="modal-footer confirm-dialog-footer">
      <fudis-button (handleClick)="cancel()" [label]="t('BUTTON.CANCEL')" variant="secondary"></fudis-button>
      <fudis-button (handleClick)="submit()" [label]="t('BUTTON.CONFIRM')"></fudis-button>
    </div>
  </form>
  </ng-container>
</ng-container>
