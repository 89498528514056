<ng-container *ngIf="(loading$ | async) === false">
  <ng-container *ngFor="let item of visibleCourseUnitsWithPeriods ?? undefined | keyvalue">
    <h4
      *ngIf="item.key !== 'undefined'">{{'ENROLMENTS.PERIOD' | translate}} {{this.getStudyPeriodNameByPeriodId(item.key) | localizedString}}
      / {{this.getStudyYearNameByPeriodId(item.key)}}</h4>
    <div class="grid-container">
      <div *ngFor="let courseUnit of item.value" class="grid-item">
        <ng-container [ngTemplateOutlet]="studyBoxTemplate" [ngTemplateOutletContext]="{courseUnit: courseUnit}" />
      </div>
    </div>
  </ng-container>
  <div class="show-more" *ngIf="totalPeriodCount > defaultPeriodCount">
    <sis-show-more *ngIf="visiblePeriodCount > defaultPeriodCount" (click)="setVisiblePeriodCourseUnits(false)" placement="top" text="{{'ENROLMENTS.SHOW_LESS_PERIODS' | translate}}"></sis-show-more>
    <sis-show-more *ngIf="totalPeriodCount > visiblePeriodCount"  (click)="setVisiblePeriodCourseUnits(true)" placement="bottom" text="{{'ENROLMENTS.SHOW_MORE_PERIODS' | translate: { count: totalPeriodCount - visiblePeriodCount } }}"></sis-show-more>
  </div>
  <ng-container *ngIf="visibleUntimedCourseUnits.length > 0">
    <h4>{{ 'ENROLMENTS.NO_PERIOD' | translate }}</h4>
    <div class="grid-container">
      <div *ngFor="let courseUnit of visibleUntimedCourseUnits" class="grid-item">
        <ng-container [ngTemplateOutlet]="studyBoxTemplate" [ngTemplateOutletContext]="{courseUnit: courseUnit}" />
      </div>
    </div>
    <div class="show-more" *ngIf="untimedCourseUnits.length > defaultCourseUnitCount">
      <sis-show-more *ngIf="visibleUntimedCourseUnits.length > defaultCourseUnitCount" (click)="setVisibleUntimedCourseUnits(false)" placement="top" text="{{'ENROLMENTS.SHOW_LESS_COURSE_UNITS' | translate}}"></sis-show-more>
      <sis-show-more *ngIf="untimedCourseUnits.length > visibleUntimedCourseUnits.length"  (click)="setVisibleUntimedCourseUnits(true)" placement="bottom" text="{{'ENROLMENTS.SHOW_MORE_COURSE_UNITS' | translate: { count:  untimedCourseUnits.length - visibleUntimedCourseUnits.length } }}"></sis-show-more>
    </div>
  </ng-container>

  <sis-contextual-notification
    variant="warning"
    *ngIf="validatablePlan && validatablePlan.getAllCourseUnitsInPlan().length === 0"
    [content]="'ENROLMENTS.NO_COURSE_UNITS_IN_PLAN_WARNING_TEXT' | translate">
    <sis-button link="true" (clicked)="navigateToPlanStructure()"
                [label]="'ENROLMENTS.NO_COURSE_UNITS_IN_PLAN_LINK' | translate"></sis-button>
  </sis-contextual-notification>
  <sis-contextual-notification
    variant="light"
    *ngIf="validatablePlan && visibleUntimedCourseUnits.length === 0 && !visiblePeriodCount && validatablePlan.getAllCourseUnitsInPlan().length > 0"
    [content]="'ENROLMENTS.NO_UNTIMED_COURSE_UNITS_IN_PLAN_TEXT' | translate">
    <sis-button link="true" (clicked)="navigateToPlanStructure()"
                [label]="'ENROLMENTS.NO_UNTIMED_COURSE_UNITS_IN_PLAN_LINK' | translate"></sis-button>
  </sis-contextual-notification>
  <sis-contextual-notification
    *ngIf="!validatablePlan"
    variant="warning"
    [content]="'ENROLMENTS.NO_PLANS_WARNING_TEXT' | translate">
    <sis-button link="true" (clicked)="navigateToPlanStructure()"
                [label]="'ENROLMENTS.NO_PLANS_WARNING_LINK' | translate"></sis-button>
  </sis-contextual-notification>
</ng-container>

<ng-template #studyBoxTemplate let-courseUnit="courseUnit">
  <sis-study-box [id]="courseUnit.id"
                 [level]="5"
                 [name]="courseUnit.name"
                 [code]="courseUnit.code"
                 [credits]="courseUnit.credits"
                 (nameClickAction)="showCourseUnitModal($event)">
    <sis-study-notification-box *ngIf="hasSelectableAssessmentItems(courseUnit) && !hasAllAssessmentItemsSelected(courseUnit) && hasCompletionMethodSelected(courseUnit)"
                                study-box=notifications
                                [primaryButtonText]="'ENROLMENTS.SELECT_REALISATIONS' | translate"
                                (primaryClickAction)="showSelectCourseUnitRealisationModal(courseUnit)">
    </sis-study-notification-box>
    <sis-study-notification-box *ngIf="hasCompletionMethodSelected(courseUnit) && hasAllAssessmentItemsSelected(courseUnit)"
                                study-box=notifications
                                [primaryButtonText]="'ENROLMENTS.CHANGE_REALISATIONS' | translate"
                                (primaryClickAction)="showSelectCourseUnitRealisationModal(courseUnit)">
      {{ 'ENROLMENTS.ALL_REALISATIONS_SELECTED' | translate }}
    </sis-study-notification-box>
    <sis-study-notification-box *ngIf="hasCompletionMethodSelected(courseUnit) && !hasSelectableAssessmentItems(courseUnit)" study-box=notifications>
      {{ 'ENROLMENTS.NO_REALISATIONS_AVAILABLE' | translate }}
    </sis-study-notification-box>
    <sis-study-notification-box *ngIf="!hasCompletionMethodSelected(courseUnit)" study-box=notifications>
      {{ 'ENROLMENTS.NO_COMPLETION_METHOD_SELECTED' | translate }}
    </sis-study-notification-box>
  </sis-study-box>
</ng-template>
